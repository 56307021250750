const P = ({children}) => (
    <p className="text-sub mb-3">{children}</p>
)

const Title = ({children, className}) => (
    <h3 className={className ? className : "mt-6"}>{children}</h3>
)

const B = ({children}) => (
    <b className="text-dark">{children}</b>
)

const Content = () => (
    <div>
       <Title className="mt-0">1. IntuiSource SERVICES AND SUPPORT</Title>
       <P>1. Definitions</P>
       <P>1.1 <B>“Affiliate”</B> means any entity that directly or indirectly controls, is controlled by, or is under common control with the Customer entity signing this Agreement. “Control”, for purposes of this definition, means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.</P>
       <P>1.2 <B>“Beta Service(s)”</B> means IntuiSource services or functionality that may be made available to Customer to try at its option which is clearly designated as beta, pilot, limited release, developer preview, non-production, evaluation, or by a similar description in an Order Form.  Beta Services as defined in this Agreement shall not include the Service, the Professional Services or the Support Services.</P>
       <P>1.3 <B>“End User”</B> means the clients of Customer who are requesting their financial institution and pay advice data be pulled by the Service for the Customer. </P>
       <P>1.4 <B>“End User Data”</B> means End User’s login, password, any other authentication information required by Company or an End User’s third party financial institution or pay advice provider, and any End User transaction data.</P>
       <P>1.5 <B>“Initial Term”</B> means the period of time commencing on the Effective Date and continues for the number of months set forth on the Order Form.</P>
       <P>1.6 <B>“Intellectual Property Rights”</B> ans unpatented inventions, patent applications, patents, design rights, copyrights, trademarks, service marks, trade names, domain name rights, mask work rights, know-how and other trade secret rights, and all other intellectual property rights, derivatives thereof, and forms of protection of a similar nature anywhere in the world.</P>
       <P>1.7 <B>“Order Form”</B> means an ordering document executed by the Parties that specifies the Services purchased by Customer under this Agreement.  Each Order Form shall incorporate this Agreement by reference.</P>
       <P>1.8 <B>“Professional Services”</B> means certain implementation, consulting, technical, education or training services as described more fully in an applicable Order Form and Spec Sheet.  Professional Services as defined in this Agreement shall not include the Service or the Support Services.</P>
       <P>1.9 <B>“Renewal Term”</B> means each successive renewal period of at least one (1) year following the Initial Term.</P>
       <P>1.10 <B>“Set-up Fee”</B> means the fee payable to IntuiSource in consideration of IntuiSource implementing the applicable Professional Services, as set forth in the applicable Order Form(s).</P>
       <P>1.11 <B>“Service”</B> means the products and services ordered by Customer identified on the Order Form(s) and made available by IntuiSource through its online web-based application or mobile components via designated websites, IP addresses, application programming interface(s), or other means.  The Service as defined in this Agreement shall not include the Professional Services or the Support Services. </P>
       <P>1.12 <B>“Spec Sheet”</B> ans a document provided by IntuiSource that describes the work by IntuiSource and Customer that is necessary to configure the Service purchased by Customer on an Order Form.  Each Spec Sheet shall be governed by this Agreement.</P>
       <P>1.13  <B>“Subscription”</B> means a User’s right, subject to the terms of this Agreement, to use the Service purchased by Customer pursuant to the terms of this Agreement and the applicable Order Form.</P>
       <P>1.14 <B>“Subscription Term”</B> means the period identified in the Order Form during which Users are authorized to use or access the Service pursuant to the terms set forth in this Agreement, unless earlier terminated.</P>
       <P>1.15 <B>“Support Services”</B> means the support services provided by IntuiSource in accordance with IntuiSource’s then-current support policy.  Support Services as defined in this Agreement shall not include the Professional Services or the Services.</P>
       <P>1.16 <B>“Term”</B> means the Initial Term together with the Renewal Term(s), if any.</P>
       <P>1.17 <B>“Third Party Data Provider”</B> means any third party entity, including financial institutions and pay advice providers which provides End User Data or other data to IntuiSource for use in the Services.</P>
       <P>1.18 <B>“User”</B> identification and password by Customer or at Customer’s direction or (ii) for whom a unique user record is created by or authorized to be created by Customer within the Service.  A User may include Customer’s Affiliates, partner(s), or other third party authorized by Customer or at Customer’s direction, and with IntuiSource’s consent, but shall not include a competitor of IntuiSource.</P>

        <Title>2. ACCESS, RESTRICTIONS AND RESPONSIBILITIES</Title>

        <P>2.1 In exchange for payment of the fees listed on the Order Form, and subject to the terms of this Agreement and any applicable Order Form, Company grants Customer a nonexclusive, royalty-free, nontransferable right, solely during the Subscription Term: (i) to access and use the Service solely for Customer’s internal business purposes; and (ii) to use the Company Materials solely in conjunction with Customer’s authorized use of the Service. Except for the limited rights expressly granted hereunder, Company reserves all rights, title and interest in and to the Services, the underlying software, the Company Materials, and the Professional Services, including all related Intellectual Property Rights inherent therein.  No rights are granted to Customer hereunder other than as expressly set forth in this Agreement.</P>
        <P>2.2 Customer shall only request End User Data through the Service that is expressly consented to by the User.  Customer may only host and/or store End User Data from locations within the United States unless otherwise approved in advance and in writing by Company, and where applicable, by an applicable Third Party Data Provider.</P>
        <P>2.3 Customer will not, directly or indirectly: send any documents obtained through Service to any other party or company for any reason, except to other parties that are involved in a legal proceeding with the End User.</P>
        <P>2.3.2 Customer will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services (“Software”); modify, translate, or create derivative works based on the Services or any Software (except to the extent expressly permitted by Company or authorized within the Services); use the Services or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or remove any proprietary notices or labels. Customer may not resell, license, or transfer the service to any other person or party for any purpose, without prior written permission from Company.</P>
        <P>2.4 Further, Customer may not remove or export from the United States or allow the export or re-export of the Services, Software or anything related thereto, or any direct product thereof in violation of any restrictions, laws or regulations of the United States Department of Commerce, the United States Department of Treasury Office of Foreign Assets Control, or any other United States or foreign agency or authority.  As defined in FAR section 2.101, the Software and documentation are “commercial items” and according to DFAR section 252.227-7014(a)(1) and (5) are deemed to be “commercial computer software” and “commercial computer software documentation.”  Consistent with DFAR section 227.7202 and FAR section 12.212, any use modification, reproduction, release, performance, display, or disclosure of such commercial software or commercial software documentation by the U.S. Government will be governed solely by the terms of this Agreement and will be prohibited except to the extent expressly permitted by the terms of this Agreement. </P>
        <P>2.5 Customer represents, covenants, and warrants that Customer will use the Services only in compliance with any of the Company’s standard published policies, terms and/or conditions then in effect and published on Company’s website (the “Policy”) and all applicable laws and regulations.  Customer hereby agrees to indemnify and hold harmless Company against any damages, losses, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees) in connection with any claim or action that arises from an alleged violation of the foregoing or otherwise from Customer’s use of Services. Although Company has no obligation to monitor Customer’s use of the Services, Company may do so and may prohibit any use of the Services it believes may be (or alleged to be) in violation of the foregoing.</P>
        <P>2.6 Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, “Equipment”).  Customer shall also be responsible for maintaining the security of the Equipment, Customer account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer account or the Equipment with or without Customer’s knowledge or consent. </P>
        <P>2.7 Subject to the terms of this Agreement, Company will use commercially reasonable efforts to provide Customer the Services. </P>
        <P>2.8 Subject to the terms hereof, Company will provide Customer with reasonable technical support services in accordance with the Company’s standard practice.</P>
        <P>2.9 In consideration of the foregoing, Customer hereby irrevocably agrees and warrants that it and its Affiliates shall not, directly or indirectly, make any arrangement with any of the Third Party Data Provider to build a competing product to Company. </P>

        <Title>3. CONFIDENTIALITY; PROPRIETARY RIGHTS</Title>

        <P>3.1 Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose business, technical or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party).  Proprietary Information of Company includes non-public information regarding features, functionality and performance of the Service.  Proprietary Information of Customer includes non-public data provided by Customer to Company to enable the provision of the Services (“Customer Data”). The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information.  The Disclosing Party agrees that the foregoing shall not apply with respect to any information after five (5) years following the disclosure thereof or any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.</P>
        <P>3.2 Customer shall own all right, title and interest in and to the Customer Data.  Company shall own and retain all right, title and interest in and to (a) the Services and Software, all improvements, enhancements or modifications thereto, (b) any software, applications, inventions or other technology developed in connection with Implementation Services or support, and (c) all intellectual property rights related to any of the foregoing.</P>
        <P>3.3 Notwithstanding anything to the contrary, Company shall have the right collect and analyze data and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning Customer Data and data derived therefrom), and  Company will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Company offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business. No rights or licenses are granted except as expressly set forth herein.</P>

        <Title>4. PAYMENT OF FEES</Title>
        
        <P>4.1 Customer will pay Company the then applicable fees described in the Order Form for the Services and Implementation Services in accordance with the terms therein (the “Fees”).  Company reserves the right to change the Fees or applicable charges and to institute new charges and Fees at the end of the Initial Service Term or then-current renewal term, upon thirty (30) days prior notice to Customer (which may be sent by email). If Customer believes that Company has billed Customer incorrectly, Customer must contact Company no later than 60 days after the closing date on the first billing statement in which the error or problem appeared, in order to receive an adjustment or credit.  Inquiries should be directed to Company’s customer support department.</P>
        <P>4.2 Company may choose to bill through an invoice, in which case, full payment for invoices issued in any given month must be received by Company thirty (30) days after the mailing date of the invoice.  Unpaid amounts are subject to a finance charge of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses of collection, including reasonable attorney fees, and may result in immediate termination of Service. Customer shall be responsible for all taxes associated with Services other than U.S. taxes based on Company’s net income.</P>

        <Title>5. TERM AND TERMINATION</Title>
        
        <P>5.1 This is a month to month agreement, and can be canceled by either party at any time. However, should Customer cancel, they shall not be entitled to any refunds, including but not limited refunds of any pre-paid credits. (the “Term”).</P>
        <P>Upon any termination, Company will make all Customer Data available to Customer for electronic retrieval for a period of thirty (30) days, but thereafter Company may, but is not obligated to, delete stored Customer Data. All sections of this Agreement which by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.</P>

        <Title>6. TAXES. </Title>
        <P>Unless otherwise stated, COMPANY’s fees do not include any direct or indirect local, state, federal or foreign taxes, levies, duties or similar governmental assessments of any nature, including value-added, sales, use or withholding taxes (collectively, “Taxes”).  Taxes do not include taxes on COMPANY’s income, property and employees.  Customer is responsible for paying all Taxes associated with its purchases hereunder.  If COMPANY has the legal obligation to pay or collect Taxes for which Customer is responsible under this section, the appropriate amount of such Taxes shall be invoiced to and paid by Customer, unless Customer provides COMPANY with a valid tax exemption certificate authorized by the appropriate taxing authority. </P>

        <Title>7. INACTIVE END USER ACCOUNTS. </Title>
        <P>IntuiSource reserves the right to cancel any End User account after 30 days if no report has been ordered. Customer will not be billed for said End User and may invite that End User to pull reports thereafter. </P>
        
        <Title>8. BETA SERVICES.  </Title>
        <P>From time to time, IntuiSource may offer Beta Services to Customer, which Customer may elect to use in its sole discretion.  The fees, if any, for Beta Services will be set forth in an Order Form.  Beta Services are for evaluation purposes only and are not supported by IntuiSource, or subject to any service level obligations.  Beta Services may be subject to additional terms set forth, and agreed to by the Parties, in a separate agreement.  IntuiSource owns all Intellectual Property Rights in and to the Beta Services and any use of the Beta Services is subject to the terms of Section 6 of this Agreement.  Unless otherwise stated in a Beta Services Order Form, (i) any Beta Services trial period will expire upon the date that a version of the Beta Services becomes generally available, (ii) either Party may discontinue Beta Services at any time in its sole discretion, and (iii) IntuiSource may never make the Beta Services generally available. </P>
        
        <Title>9. MUTUAL RIGHTS AND RESPONSIBILITIES</Title>
        <P>9.1 COMPANY’s Responsibilities.  COMPANY shall  (i) use commercially reasonable efforts to make the Service available in accordance with its then-current support policies; (COMPANY reserves the right to update its support policies and its service levels at any time in its sole discretion provided that any updates shall not materially diminish the level of support or the service levels provided to Customer during the Term in which COMPANY updates such policies or service levels.  COMPANY may provide notices and communications directed to its customer base generally by means of a general notice through the Service, or by electronic mail to Customer’s administrator e-mail address on record in COMPANY’s account information.</P>
        <P>9.2 Customer Responsibilities.  Customer shall: (i) prevent unauthorized access to or use of the Services and notify COMPANY promptly of any such unauthorized access or use;  (ii) use and provide access to the Services only in accordance with this Agreement and applicable law and (iii) maintain the security of the End User Data and the Service in accordance with industry standards. </P>
                
        <Title>10. WARRANTIES AND DISCLAIMERS</Title>
        <P>10 A - Mutual Warranties.  Each Party represents and warrants that it has the legal power and authority to enter into and perform its obligations under this Agreement and will comply in all material respects with applicable law related to the performance by it of its obligations under this Agreement.</P>
        <P>10 B - Company Warranties. Company warrants that the End User data produced by the Service is an accurate representation of information provided to Company by the financial institutions, pay advice providers, the End User, or third-party data providers. Company takes no responsibility for the lack of availability or accuracy of any End User data, original statements by financial institutions, pay advice providers, or data third party data providers.</P>
        <P>10 C - Customer Warranties.  Customer hereby warrants that: (i) has all rights necessary, including all consents and/or licenses, and has made any applicable disclosures to make the End User’s Data available to Company for the Service and any applicable Beta Services; (ii) it is providing User Data to Company in accordance with applicable law; (iii) it will use and provide access to the Services only in accordance with this Agreement; (iv) it will not make representations, warranties or guarantees to Users, or any other third party with respect to the specifications, features or capabilities of Company and/or the Service that are inconsistent with the terms of this Agreement;</P>
        <P>10 D - Disclaimer.  EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, CUSTOMER UNDERSTANDS AND AGREES THAT THE SERVICE, BETA SERVICES, PROFESSIONAL SERVICES, END USER DATA, AND CONTENT ARE PROVIDED “AS IS” AND COMPANY, ITS AFFILIATES, SUPPLIERS, THIRD PARTY DATA PROVIDERS,  RESELLERS, AND ITS LICENSORS MAKE NO WARRANTIES OF ANY KIND WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, QUIET ENJOYMENT, SATISFACTORY QUALITY, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.  IN ADDITION, CUSTOMER ACKNOWLEDGES AND AGREES (i) THE SERVICE, PROFESSIONAL SERVICES, AND BETA SERVICES DO NOT CONSTITUTE THE PROVISION OF LEGAL ADVICE OR SERVICES IN ANY MANNER; (ii) THE SERVICE AND BETA SERVICES DO NOT ENSURE CUSTOMER’S COMPLIANCE WITH ALL APPLICABLE LAW; AND (iii) CUSTOMER IS SOLELY RESPONSIBLE FOR ITS COMPLIANCE WITH ALL APPLICABLE LAWS.  COMPANY, ON BEHALF OF ITSELF AND ALL THIRD PARTY DATA PROVIDERS, EXPRESSLY DISCLAIMS ANY TYPE OF REPRESENTATION OR WARRANTY REGARDING THE AVAILABILITY OR RESPONSE TIME OF THE SERVICE OR END USER DATA OR THAT ACCESS TO THE SERVICE OR END USER DATA WILL BE UNINTERRUPTED OR ERROR-FREE AND, EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, EXPRESSLY DISCLAIMS THE ACCURACY, COMPLETENESS AND CURRENCY OF ALL END USER DATA. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST.  CUSTOMER MAY HAVE OTHER RIGHTS WHICH VARY FROM JURISDICTION TO JURISDICTION.</P>
        <P>10 E - Company will make an effort to keep connections open to financial and employment-based accounts for 90 days. During this time, updated information can be pulled from various connected End User accounts. We reserve the right to close this connection on or after 90 days from when the first connection is made to an institution or data provider. Company makes no guarantees that these ongoing connections will remain open. After the 90-day period, additional charges may apply for re-connected accounts.</P>

        <Title>11. LIMITATIONS OF LIABILITY</Title>
        <P>NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF A PERSON, COMPANYAND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND COMPANY’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE,WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</P>

        <P>a. Audit</P>
        <P>Customer hereby grants authority to IntuiSource to access the End User data pulled through the Service. That access is needed for several reasons included, but not limited, to rendering support to Customer, fulfilling our own auditing and security obligations, rendering support to Customer, and in order to test the system.</P>
        <P>b.Security Incident</P>
        <P>(a) Each Party will promptly (and in any event, within 48 hours of discovery) notify the other Party of a Security Incident, as defined to mean any breach, incident or other event that compromises (or would be reasonably likely to result in a compromise of) the security, integrity or confidentiality of the End User Data or other Party’s Confidential Information, or that otherwise results in (or that would be reasonably likely to result in) the unauthorized access, use, disclosure or loss of End User Data or the other Party’s Confidential Information..  Such notice shall include a detailed description of the Security Incident, and any other information the other Party may reasonably request concerning the Security Incident, including, without limitation, the number of records, types of information, and number of Users impacted by the Security Incident, the known or suspected causes of the Security Incident, any actual or anticipated impact on the other Party or its customers, and remediation plans. </P>
        <P>(b) Each Party further agrees to fully cooperate with and provide all reasonable assistance to the other Party in regard to its investigation of any Security Incident.  Without limiting the generality of the foregoing, each Party shall cooperate with the other Party in determining its legal obligations with respect to notification of Users, regulators, and/or law enforcement, if any, and each Party agrees to provide to the other any documentation in such Party’s possession which is necessary for the other to issue required or advisable notifications or communications.</P>


        <Title>12. MISCELLANEOUS</Title>
        <P> any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.  This Agreement is not assignable, transferable or sublicensable by Customer except with Company’s prior written consent.  Company may transfer and assign any of its rights and obligations under this Agreement without consent.  This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement, and that all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein. </P>
        <P>No agency, partnership, joint venture, or employment is created as a result of this Agreement and Customer does not have any authority of any kind to bind Company in any respect whatsoever.  In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled to recover costs and attorneys’ fees.  All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; the day after it is sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt requested.  This Agreement shall be governed by the laws of the State of Michigan without regard to its conflict of laws provisions. And the venue for any litigation on this agreement shall be Wayne County, Michigan. </P>


    </div>
)

export default Content;