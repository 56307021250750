import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from 'assets/img/brand/IntuiSourceLogo.png';

import { Col, Row } from 'reactstrap';

import Man1 from './_images/man1.png';
import Man2 from './_images/man2.png';
import Man3 from './_images/man3.png';
import Man4 from './_images/man4.png';
import Woman1 from './_images/woman1.png';
import Woman2 from './_images/woman2.png';
import Woman3 from './_images/woman3.png';
import Woman4 from './_images/woman4.png';
import Woman5 from './_images/woman5.png';


import svg1 from './_images/svg1.svg';
import svg2 from './_images/svg2.svg';
import svg4 from './_images/svg4.svg';
import svg5 from './_images/svg5.svg';

import people from './_images/people.png';


const Book = () => {

    return (
        <div>

        <div className='border-bottom bg-white'>
            <Container className="pt-3 mb-4 ">
                <Row>
                    <Col lg={4} className="align-self-center">
                        <img src={Logo} className="mr-3 mr-md-6" alt="biz head law" style={{width: 170, position: 'relative', top: -4}} />
                    </Col>
                    <Col lg={8} className="text-right align-self-center">
                        <ul className='mb-0 pl-0'>
                            <li onClick={() => document.getElementById('archk-difference').scrollIntoView()} className='list-style-none cursor-pointer d-inline px-4'>For Applicants</li>
                            <li onClick={() => document.getElementById('archk-how').scrollIntoView()} className='list-style-none cursor-pointer d-inline px-4'>For Companies</li>
                            <li  className='list-style-none d-inline px-4'><button onClick={() => document.getElementById('archk-contact').scrollIntoView()} className="btn btn-lg btn-success">Contact Us</button></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>

            <Container className="pt-8">
                <Row>
                    <Col lg={7} className="align-self-cener">
                        <h1 className='display-1 mb-4' style={{lineHeight: 1.1}}>Enhancing Company & Staffing Relations</h1>
                        <h2>We create relationships built to last by bringing the best talent from around the world right to your door.</h2>

                        <div className='p-3 border border my-5 rounded'>
                            <h4><i className="fas fa-info-circle text-info-original " /> Our network of highly qualified individuals powers company growth with affordable rates, no red tape, and a "Done For You" human resources model.</h4>
                        </div>

                        <div className="mb-5 pb-6">
                        <h2 className='display-3 mt-4'>Say Hi To Your New Team</h2>
                        <img className="border z-depth-5" src={Woman1} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -0}} alt="attorney" />
                        <img className="border z-depth-5" src={Man1} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -15}} alt="attorney" />
                        <img className="border z-depth-5" src={Woman2} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -30}} alt="attorney" />
                        <img className="border z-depth-5" src={Woman3} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -45}} alt="attorney" />
                        <img className="border z-depth-5" src={Man2} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -60}} alt="attorney" />
                        <img className="border z-depth-5" src={Man3} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -75}} alt="attorney" />
                        <img className="border z-depth-5" src={Man4} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -90}} alt="attorney" />
                        <img className="border z-depth-5" src={Woman4} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -105}} alt="attorney" />
                        <img className="border z-depth-5" src={Woman5} style={{width: 50, height: 50, borderRadius: '100%', position: 'relative', left: -120}} alt="attorney" />
                        </div>
                    </Col>
                    <Col lg={5} className="align-self-end mt-6 mt-lg-0">
                        <img src={people} className="w-100" alt="..." />
                    </Col>
                </Row>

            </Container>

            <div className="bg-white py-7">
                <Container>
                    <div className="text-right mb-5 pr-5"><small>Your new team member is <b className='font-weight-bold text-underline'>right around the corner</b> </small></div>
                    <Row>
                        <Col lg={3} className="text-center">
                            <img src={svg5} className="pb-4 w-50 w-50-sm" style={{width: 200, height: 150, borderRadius: ''}} alt="..."  />
                            <h3 className='text-dark d-none d-lg-block'>IntuiSource is employment made simple at a fraction of the cost</h3>
                            <h3 className='text-dark d-block d-lg-none display-2 pb-7'>You Don't Need To Deal With The IRS or Wait Behind Red Tape</h3>
                        </Col>
                        <Col lg={3} className="text-center">
                            <img src={svg1} className="pb-4 w-50-sm" style={{width: 200, height: 150, borderRadius: ''}} alt="..." />
                            <h3 className='text-dark d-none d-lg-block'>Instant access to a network of qualified and screened individuals</h3>
                            <h3 className='text-dark d-block d-lg-none display-2 pb-7'>Speak With Real Attorneys Via Phone Or Video Conference</h3>
                        </Col>
                        <Col lg={3} className="text-center">
                            <img src={svg2} className="pb-4 w-50" style={{width: 200, height: 150, borderRadius: ''}} alt="..." />
                            <h3 className='text-dark d-none d-lg-block'>Dynamic talent to meet the needs of your unique business</h3>
                            <h3 className='text-dark d-block d-lg-none display-2 pb-7'>Insights From 9 Former Congressional Policy Makers</h3>
                        </Col>
                        <Col lg={3} className="text-center">
                            <img src={svg4} className="pb-4" style={{width: 200, height: 150, borderRadius: ''}} alt="..." />
                            <h3 className='text-dark d-none d-lg-block'>Battle Tested and ready to get you the results that you deserve</h3>
                            <h3 className='text-dark d-block d-lg-none display-2 pb-7'>Battle Tested and ready to get you the results that you deserve</h3>
                        </Col>
                    </Row>
                </Container>
            </div>
          
            <div id="archk-how" className="bg-secondary py-7 border-top">
                <Container>
                
                    <h2 className="display-2 text-dar text-warning ">How Does IntuiSource Work <i className="fas fa-question " /></h2>

                    <Row className="py-6">
                        <Col lg={6} className="align-self-center">
                            <h2 className='text-dark display-3 mb-4 mb-lg-0'>1) Tell Us About Your Needs</h2>
                        </Col>
                        <Col lg={6} className="align-self-center">
                            <p className="text-dark mb-0">Every business is unique and every position ready to be filled needs a qualified individual. Once we know what your looking for we curate a hand selected group of individuals to choose from. This allows us to do the work until we are sure we have a group of candidates ready for you to review.</p>
                        </Col>
                    </Row>

                    <Row className="py-6">
                        <Col lg={6} className="align-self-center d-block d-lg-none">
                            <h2 className='text-dark display-3 mb-4 mb-lg-0'>2) The Interview Process</h2>
                        </Col>
                        <Col className="align-self-center" lg={6} >
                            <p className="text-dark mb-0">Once we have a group of candidates we will schedule online video calls for you to meet and greet each potential team member. Here you can take the reigns and conduct an interview yourself or we can conduct the interview and you can observe. Which ever method fits your preference.</p>
                        </Col> 
                        <Col lg={6} className="align-self-center  d-none d-lg-block">
                            <h2 className='text-dark display-3 mb-4 mb-lg-0'>2) The Interview Process</h2>
                        </Col>
                    </Row>

                    <Row className="py-6">
                        <Col lg={6} className="align-self-center">
                            <h2 className='text-dark display-3 mb-4 mb-lg-0'>3) Team Member Onboarding</h2>
                        </Col>
                        <Col lg={6} className="align-self-center">
                            <p className="text-dark mb-0">Once we've found the right fit to fill the position you are looking for, we will place your new team member with you directly. We know from experience, that a direct 1-on-1 relationship is best for both you and the team members within your business. Here you have full ability to delegate tasks, roles, and other jobs as you see fit. </p>
                        </Col>
                    </Row>

                    <Row className="py-6">
                        <Col lg={6} className="align-self-center d-block d-lg-none">
                            <h2 className='text-dark display-3 mb-4 mb-lg-0'>4) As Needed Check Ins</h2>
                        </Col>
                        <Col lg={6} className="align-self-center">
                            <p className="text-dark mb-0">While we insist that you to take over the day to day relationship team members as it creates the best environment for all parties involved, we are here to help every step of the way on any task that is outside of this. Schedule check in calls with us at any time to discuss payroll, performance, team member life events, etc.  </p>
                        </Col>
                        <Col lg={6} className="align-self-center  d-none d-lg-block">
                            <h2 className='text-dark display-3 mb-4 mb-lg-0'>4) As Needed Check Ins</h2>
                        </Col>
                    </Row>
                    
                </Container>
            </div>

            <div id="archk-difference" className='bg-white border-top  py-7'>
                <Container className="py-5" style={{width: 900}}>
                    <h2 className='display-2 text-center text-dar text-warning mb-4'>Why We're Different & Stand Alone</h2>
                    <p>While we can write a list of every generic thing that we do and call them differences we'll keep it short and focused on the key thing that sets IntuiSource apart from other HR and staffing Companies</p>

                    <hr />

                    <Row>
                        <Col lg={6}>
                            <h2>Employers: Performance Based Pay</h2>
                            <p>Let's face it, having a team member sick or underperforming is both frustrating and expensive. While they produce nothing for your business during these times you still need to pay them.</p>

                            <p>IntuiSources payment model means you only pay for the hours your team members work. We cover the difference with our member credit model so time off, power outages, etc. do not come out of your bottom line. </p>

                            <p>This also insures your team members have flexibility and are well compensated keeping them performing at the highest levels</p>
           
           
                            <h2>Employers: Pass Through Incentives</h2>
                            <p>The IntuiSource team members are highly motivated and optimistic people. Any time you wish to bonus your team members or give them a raise 100% goes to the team member. </p>
                            <p>If you have someone who stands above the rest you have full control in compensating them for it. </p>
                        </Col>

                        <Col lg={6}>
                            
                            <h2>Applicants: The Member Credit Model</h2>
                            <p>IntuiSources mission is to bring the American Dream outside of America. We accomplish this by rewarding our team with incentives based on performance and time put in.</p>
                            <p>We value hard work, dedication, and make sure that the money you get paid reflects this.</p>
                            
                            <p>Additionally, our member credit model automatically builds up credits as you work that you can redeem for paid time away. </p>

                            <p>This gives you the security to know that on days you are sick or need time away you can still get compensated and are not at risk of losing some or part of your income stream.</p>
                            
                            <p>Unlike with other companies, we take great pride in taking care of our team members.</p>

                        </Col>
                    </Row>



                </Container>
            </div>

            <div className='bg-secondary border-top py-7'>
                <Container>
                    <h2 className="text-dark display-3 mb-5">Frequently Asked Questions</h2>

                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. How does compensation work?</p>
                    <p className="text-dark">Compensation for each team member is due on the 1st and 15th of each month via an auto pay ACH.</p>
                
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. Does each team member work on salary?</p>
                    <p className="text-dark">No, team members work on an hourly model so you only pay for the time that they are producing. With out Member Credit model we will cover the cost of sick days and required time off so you pay nothing outside the hours your team member works.</p>
                 
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. What happens if a team member is not working out?</p>
                    <p className="text-dark">Should a team member not be performing up to your standards or the match was not a great fit we take it from there. IntuiSource handles everything at the time a separation is needed.</p>
              
                    <p className="text-dark text-uppercase font-weight-bold mb-0">Q. How many team members can I get through IntuiSource?</p>
                    <p className="text-dark">As many as you need, as your demand grows we will connect you with more and more talent to match the capacity of your company.</p>

                </Container>
            </div>

            <div className='bg-secondary border-top  py-7' id="archk-contact">
                <Container className="py-5" style={{width: 900}}>
                    <h2 className='display-2 text-cente text-dar text-warning mb-4'>Contact Us</h2>
                    <p>We hate generic contact forms just as much as you. If you're a company or an applicant we'd love to talk with you directly.</p>

                    <hr />

                    <p>Send an email to us at the address below and we'll talk with you shortly. <br />(We do not use a ticketing system, a real human will answer you back)</p>

                    <h2 className='display-3  text-dar text-warnin text-info mb-4'>carlos@intuisource.com</h2>


                </Container>
            </div>

            <div className='border-top'>
                <Container className='pb-5 pt-6'>
                    <h2 className='mb-5 '><span className='text-ppercase'>IntuiSource</span></h2>
                    <Row>
                        <Col lg={4}>
                            <h4>Business Hours</h4>
                            <p>Mon – Fri: 8AM – 5PM EST</p>
                            <h4>Contact</h4>
                            <p>carlos@intuisource.com</p>
                        </Col>
                        <Col lg={4}>
                            <h4>Address</h4>
                            <p>11455 East 13 Mile Rd, Suite 202<br /> Warren, MI 48093</p>
                        </Col>

                        <Col lg={4}>
                            <h4>Legal</h4>
                            <p className='mb-0'><Link style={{color: 'inherit'}} to="/terms">Terms And Conditions</Link></p>
                            <p className='mb-0'><Link style={{color: 'inherit'}} to="/privacy">Privacy Policy</Link></p>
                        </Col>
                    </Row>

                </Container>

                <div className='border-top py-5 bg-white text-center'>
                    <Container>
                        <img src={Logo} className="mr-3 mr-md-6" alt="biz head law" style={{width: 200}} />
                    </Container>
                </div>

            </div>

        </div>
    )
}

export default Book;