/*
Documentation

this is the wrapping component for all auth routes
if a user is already known and logged in it will redirect
them off to their destination

if not destination is know we will redirect to /admin

*/

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {  Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";


const Site = () => {
    const history = useHistory();

    const getRoutes = routes => routes.map((prop, key) => (
        <Route
            path={`${prop.path}`}
            component={prop.component}
            key={key}
        />
    ));

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [history.location.pathname])

    // console.log(history)

    return (

        <div className="main-conten" >
            <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/" />
            </Switch>
        </div>

    );

}

export default Site