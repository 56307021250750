import Home from '../pages/Home';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';

const routes = [
    {
        path: "/privacy",
        component: Privacy,
    },
    {
        path: "/terms",
        component: Terms,
    },
    {
        path: "/",
        component: Home,
    },
  
]

export default routes;